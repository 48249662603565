import { useQuery } from 'react-query'

type User = {
  id: string
  name: string
  username: string
  email: string
  address: {
    street: string

  }
}

const fetchUserList = async () => {
  const res = await fetch('https://jsonplaceholder.typicode.com/users')
  return res.json()
}

const UserList: React.FC = () => {
  const { data, isLoading, isError, error} = useQuery<User[], Error>('userList', fetchUserList)

  if (isLoading) {
    return <span>Loading...</span>
  }
  if (isError) {
    return <span>Error: {error.message}</span>
  }

  return (
    <div>
      <h2>ユーザ一覧</h2>
      <div>
        {
          data?.map((user) => (
            <div key={user.id}>{user.name}</div>
          ))
        }
      </div>
    </div>
  )
}

export default UserList