import Hello from '@common/Hello'
import UserList from '@common/UserList'
import { QueryClient, QueryClientProvider } from 'react-query'

const queryClient = new QueryClient()

const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <UserList />
      <Hello />
    </QueryClientProvider>

  )
}

export default App
